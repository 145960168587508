var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sector
    ? _c(
        "div",
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _vm.sector.id
                                ? _c("v-card-title", [
                                    _vm._v(
                                      "Editing " +
                                        _vm._s(_vm.translate(_vm.sector.name))
                                    ),
                                  ])
                                : _c("v-card-title", [
                                    _vm._v("Creating new sector"),
                                  ]),
                              _c(
                                "v-card-text",
                                [
                                  _c("m-field", {
                                    attrs: { m: "sector", a: "name", i18n: "" },
                                    model: {
                                      value: _vm.sector.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sector, "name", $$v)
                                      },
                                      expression: "sector.name",
                                    },
                                  }),
                                  _c("m-field", {
                                    attrs: {
                                      m: "sector",
                                      a: "domain",
                                      component: "v-select",
                                      items: ["SP", "SS", "ST"],
                                    },
                                    model: {
                                      value: _vm.sector.domain,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.sector, "domain", $$v)
                                      },
                                      expression: "sector.domain",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1841434296
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }